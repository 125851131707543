'use client'

import { FC, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  selectRacePreparationEntryPrice,
  selectRacePreparationRaceType,
  setRacePreparationEntryPrice,
  setRacePreparationRaceType,
} from '@/redux/features/racePreparationSlice'
import { TonRaceStepWaitingComponent } from './TonRaceStepWaitingComponent'
import { setRoute } from '@/redux/features/router'
import { RoutePaths } from '@/services/routePaths/routePaths'
import { TonEntryPrice } from '@/types/game'
import { useTelegram } from '@/app/providers/TelegramProvider'
import { getTelegramName } from '@/utils/user'
import { selectUser } from '@/redux/features/user'
import { RaceType } from '@/types/race'
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys'
import { selectTimeDifference } from '@/redux/features/time'
import { setQueryOptionsUserReFetchInterval } from '@/redux/features/queryOptionsSlice'
import { PRICE_DIVIDER } from '@/constants/race'
import { useMutation_CancelTonTransaction_User } from '@/services/useApi/user/useMutation'
import { UserRaceStatus } from '@/types/user'

interface Props {}

export const TonRaceStepWaitingContainer: FC<Props> = ({}) => {
  const dispatch = useAppDispatch()
  const { user: userTelegram } = useTelegram()

  const raceType = useAppSelector(selectRacePreparationRaceType)
  const entryPrice = useAppSelector(selectRacePreparationEntryPrice)
  const timeDifference = useAppSelector(selectTimeDifference)

  const { waitingForTonGameData, tgId, raceStatus } = useAppSelector(selectUser)

  const playersData = useMemo(() => {
    return waitingForTonGameData?.playersData
      ? waitingForTonGameData?.playersData.filter((item) => item.tgId !== tgId)
      : []
  }, [waitingForTonGameData])

  useEffect(() => {
    if (waitingForTonGameData?.entryPrice) {
      const entryPrice = (
        (Number(waitingForTonGameData.entryPrice) / 1e9) *
        PRICE_DIVIDER
      ).toString() as TonEntryPrice

      dispatch(setRacePreparationEntryPrice(entryPrice))
    }

    if (waitingForTonGameData?.limit) {
      dispatch(
        setRacePreparationRaceType(
          waitingForTonGameData.limit === 2 ? RaceType.OneVsOne : RaceType.OneVsThree
        )
      )
    }
  }, [waitingForTonGameData])

  const { mutateAsync, isPending } = useMutation_CancelTonTransaction_User(() => {
    dispatch(setRoute(RoutePaths.tonRaceStepRaceType))
  })

  useEffect(() => {
    dispatch(setQueryOptionsUserReFetchInterval(1000))

    return () => {
      dispatch(setQueryOptionsUserReFetchInterval(false))
    }
  }, [])

  useEffect(() => {
    if (raceStatus !== UserRaceStatus.TON_PENDING) {
      dispatch(setRoute(RoutePaths.tonRaceStepRaceType))
    }
  }, [raceStatus])

  return (
    <TonRaceStepWaitingComponent
      raceType={raceType}
      entryPrice={entryPrice as TonEntryPrice}
      onCancelBtnClick={async () => {
        await mutateAsync()
      }}
      isLoading={isPending}
      userName={getTelegramName(userTelegram)}
      secondPlayer={playersData.length >= 1 ? playersData[0]?.username : ''}
      thirdPlayer={playersData.length >= 2 ? playersData[1]?.username : ''}
      fourthPlayer={playersData.length >= 3 ? playersData[2]?.username : ''}
      startTime={window.localStorage.getItem(LOCAL_STORAGE_KEYS.matchingRaceStartTime)}
      timeDifference={timeDifference}
    />
  )
}
